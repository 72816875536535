export default [
    {
      "id": 1,
      "topicName": "Web Development Internship",
      "description": "Learn to create Machine Learning Algorithms in Python and R from two Data Science experts. Code templates included.",
      "image": "./assets/webdev.jpeg",
      "price": "199",
      "slug_name": "web-development-internship"
    },
    {
      "id": 2,
      "topicName": "AI ML Developer Internship",
      "description": "Highly motivated student with a strong foundation in AI and Machine Learning. Seeking an internship to apply my skills in algorithm development, model training, and data analysis.",
      "image": "./assets/machinelearning.jpeg",
      "price": "299",
      "slug_name": "ai-ml-developer-internship"
    },
    {
      "id": 3,
      "topicName": "Human Resource Internship",
      "description": "Highly motivated student seeking an HR internship to gain experience in supporting employee relations, recruitment, and talent management.",
      "image": "./assets/hr.jpeg",
      "price": "299",
      "slug_name": "human-resource-internship"
    }
  ]
  