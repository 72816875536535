import React from 'react'
import styles from './Mentor.module.css';

import linkdin1 from "./linkdin.png"


const Mentors = ({ imgSrc, name, title, description, linkdin }) => {
    return (
        <>
            <div className={styles.hello}>
                <div className={styles.mentor}>
                    <img src={imgSrc} alt={name} className={styles.image} />
                    <h3>{name}</h3>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.description}>{description}</p>

                    <div className={styles.socialLinks} style={{ marginTop: "2rem" }}>

                        <a href={linkdin}><img src={linkdin1} alt="LinkedIn" /></a>

                    </div>
                </div>

            </div>
        </>
    )
}

export default Mentors
