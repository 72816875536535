export default [
  {
    id: 1,
    topic_name: "Machine Learning",
    description:
      "Comprehensive live classes scheduled to cover Machine Learning concepts and applications.",
    know_more: {
      price: "849₹",
      contact_mail: "info@iqpaths.com",
      things_covered: [
        "Introduction to Machine Learning",
        "Supervised Machine Learning",
        "Regression",
        "Classification",
        "Unsupervised Machine Learning",
        "clusturing",
        "Capstone Project",
        "Seasoned trainer with over 15 years of expertise in the AI/ML field",
        "1 Month training",
        "Completion certificate from iQpaths pvt ltd",
      ],
    },
    message:
      "After the purchase, our team will contact you within 24 working hours. Live class batch schedules will be provided to you via call and email, where you can choose your slots.",
    live_now: "Registrations starts from 15 july",
    slug_name: "machine-learning",
  },
  {
    id: 2,
    topic_name: "Dive into Python Programming",
    description:
      "Comprehensive live classes scheduled to cover Python programming from basics to advanced topics.",
    know_more: {
      price: "649₹",
      contact_mail: "info@iqpaths.com",
      things_covered: [
        "Introduction of python",
        "Introduction And Installation of python",
        "Basic python Operations",
        "Advance python libraries ",
        " Python Control Flow",
        "Functions and Modules:",
        "Practice projects",
        "Seasoned trainer with over 15 years of expertise in the Industry",
        "2 Weeks training",
        "Completion certificate from iQpaths pvt ltd",
      ],
    },
    message:
      "After the purchase, our team will contact you within 24 working hours. Live class batch schedules will be provided to you via call and email, where you can choose your slots.",
    live_now: "currently not  available ",
    slug_name: "dive-into-python-programming",
  },
  {
    id: 3,
    topic_name: "Structure Query Language (SQL)",
    description:
      "Comprehensive live classes scheduled to cover data management and manipulation using SQL.",
    know_more: {
      price: "649₹",
      contact_mail: "info@iqpaths.com",
      things_covered: [
        "Introduction of SQL",
        "Introduction And Installation of Oracle",
        "Basic SQL Operations",
        "Data Definition Language (DDL)",
        "Data Manipulation Language (DML)",
        "Data Control Language (DCL)",
        "Transaction Control Language (TCL)",
        "PL/SQL",
        "Practice of Complex Query",
        "Seasoned trainer with over 15 years of expertise in the Industry",
        "2 Weeks training",
        "Completion certificate from iQpaths pvt ltd",
      ],
    },
    message:
      "After the purchase, our team will contact you within 24working hours. Live class batch schedules will be provided to you via call and email, where you can choose your slots.",
    live_now: "Registrations starts from 15 july",
    slug_name: "Structure-Query-Language-(SQL)",
  },
  {
    id: 4,
    topic_name: "Web Development Internship",
    description:
      "Gain hands-on experience in web development with leading professionals in the industry.",
    know_more: {
      price: "Stipend provided according to role",
      contact_mail: "hr@iqpaths.com",
      things_covered: [
        "Frontend and Backend Development",
        "Web Design and User Experience",
        "Version Control with Git",
        "Real-world Projects and Mentorship",
      ],
    },
    message:
      "After the application, our team will contact you within 24 working hours. Internship details and schedules will be provided via call and email.",
    live_now: "currently not available",
    slug_name: "web-development-internship",
  },
  {
    id: 5,
    topic_name: "AI ML Developer Internship",
    description:
      "Work on cutting-edge AI and ML projects and learn from experts in the field.",
    know_more: {
      price: "Stipend provided according to role",
      contact_mail: "hr@iqpaths.com",
      things_covered: [
        "Machine Learning Algorithms",
        "Data Preprocessing and Analysis",
        "Model Training and Evaluation",
        "Real-world Projects and Mentorship",
      ],
    },
    message:
      "After the application, our team will contact you within 24 working hours. Internship details and schedules will be provided via call and email.",
    live_now: "not currently available",
    slug_name: "ai-ml-developer-internship",
  },
  {
    id: 6,
    topic_name: "Human Resource Internship",
    description:
      "Develop HR skills and gain experience working with professionals in HR departments.",
    know_more: {
      price: "Stipend provided according to role",
      contact_mail: "hr@iqpaths.com",
      things_covered: [
        "Recruitment and Onboarding",
        "Employee Relations",
        "HR Policies and Compliance",
        "Real-world Projects and Mentorship",
      ],
    },
    message:
      "After the application, our team will contact you within 24 working hours. Internship details and schedules will be provided via call and email.",
    live_now: "currently  not available",
    slug_name: "human-resource-internship",
  },
  {
    id: 7,
    topic_name: "Placement Guidance Program Basic",
    description:
      "Comprehensive resume review and correction service through personal meetings by employees working in top MNCs.",
    know_more: {
      price: "349₹",
      contact_mail: "hr@iqpaths.com",
      things_covered: [
        "Resume Building",
        "Tips & Strategies to handle personal interview",
        "Company Specific Mock Technical Interview",
        "Interviewer from top MNCs Like: TCS, Capgemini, Accenture, J.P. Moron, IBM…",
        "job Assistance",
      ],
    },
    message:
      "After the purchase, our team will contact you within 24 working hours. Your mock Interview  will be conducted within 1 week after the purchase.Additionally, you may have the opportunity to receive a referral from the interviewer for a position within their company, so it's essential to prepare accordingly.",
    live_now: "Registration starts from 10 july onwards",
    slug_name: "Placement-Guidance-Program-Basic",
  },
  {
    id: 8,
    topic_name: "Placement Preparation Program Premium",
    description:
      "All-in-one package for resume correction,Apptitude,  technical interviews, and HR interview preparation through personal meetings by employees working in top MNCs.",
    know_more: {
      price: '449₹',
      contact_mail: "hr@iqpaths.com",
      things_covered: [
        "Aptitude Test",
        "Resume Building",
        "HR round preparation",
        "Technical Interview",
        "Company Specific Mock Technical Interview ",
        "Tips & Strategies to handle personal interview",
        "Interviewer from top MNCs Like: TCS, Capgemini, Accenture, J.P. Moron, IBM...",
        "Job Assistance"
      ],
    },
    message:
      "After the purchase, our team will contact you within 24 working hours. A meeting and aptitude rounds will be conducted within 1 week after the purchase.Additionally, you may have the opportunity to receive a referral from the interviewer for a position within their company, so it's essential to prepare accordingly.",
    live_now: "Registration starts from 10 july onwards",
    slug_name: "Placement-Preparation-Program-Premium",
  },
  {
    id: 9,
    topic_name: "Placement Preparation Program Ultimate",
    description:
      "All-in-one package for resume correction,Apptitude, coding round, technical interviews, and HR interview preparation through personal meetings by employees working in top MNCs.",
    know_more: {
      price: '849₹',
      contact_mail: "hr@iqpaths.com",
      things_covered: [
        "Aptitude Test",
        "Coding Mock Test",
        "Resume Building",
        "HR round preparation",
        "Company Specific Mock Technical Interview ",
        "Tips & Strategies to handle personal interview",
        "Interviewer from top MNCs Like: TCS, Capgemini, Accenture, J.P. Moron, IBM...",
        "Job Assistance",
      ],
    },
    message:
      "After the purchase, our team will contact you within 24 hours. A meeting and aptitude rounds will be conducted within 1 week after the purchase.Additionally, you may have the opportunity to receive a referral from the interviewer for a position within their company, so it's essential to prepare accordingly.",
    live_now: "coming soon",
    slug_name: "Placement-Preparation-Program-Ultimate",
  },
];
