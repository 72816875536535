export default [
    {
        "id": 1,
        "topicName": "Machine Learning",
        "description": ["Introduction to Machine Learning","Supervised Machine Learning","Regression","Classification","Unsupervised Machine Learning","clusturing","Capstone Project","Seasoned trainer with over 15 years of expertise in the AI/ML field","1 Month training","Completion certificate "],
        "image": "./assets/courseimage1.png",
        "price": "849",
        "slug_name": "machine-learning"
      },
      {
        "id": 2,
        "topicName": "Structure Query Language (SQL)",
        "description": ["Introduction of SQL","Introduction And Installation of Oracle","Basic SQL Operations","Data Definition Language (DDL)","Data Manipulation Language (DML)","Data Control Language (DCL)","Transaction Control Language (TCL)","PL/SQL","Practice of Complex Query","Seasoned trainer with over 15 years of expertise in the Industry","2 Weeks training","Completion certificate"],
        "image": "./assets/sql.jpg",
        "price": "649",
        "slug_name": "Structure-Query-Language-(SQL)"
      },
      {
        "id": 3,
        "topicName": "Dive into Python Programming",
        "description":["Introduction of python","Introduction And Installation of python","Basic python Operations","Advance python libraries "," Python Control Flow","Functions and Modules:","Practice projects","Seasoned trainer with over 15 years of expertise in the Industry","2 Weeks training","Completion certificate "],
        "image": "./assets/courseimage2.png",
        "price": "649",
        "slug_name": "dive-into-python-programming"
      }
]