import React from 'react'
import "./About.css"
import { homeAbout } from "../../dummydata"
import Heading from '../Common/Heading/Heading'
import Photo from "./about.webp"

const About = () => {
    return (
        <>
            <section className='aboutHome'>
                <div className='container flexSB'>
                    <div className='left row hello'>
                        <img src={Photo} alt='' />
                    </div>
                    <div className='right row'>
                        <Heading subtitle='LEARN ANYTHING' title='Transform Your Future with IQPaths' />
                        <div className='items'>
                            {homeAbout.map((val) => {
                                return (
                                    <div className='item flexSB'>
                                        <div className='text hhh'>
                                            <h2>{val.title}</h2>
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About
