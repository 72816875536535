import React from 'react'
import styles from './Testimonial.module.css';
const Testimonial = ({ imgSrc, name, text, rating, post }) => {
    return (
        <>
            <div className={styles.hello}>

                <div className={styles.testimonial}>
                    <img src={imgSrc} alt={name} className={styles.image} />
                    <h3>{name}</h3>
                    <h4>{post}</h4>
                    <p className={styles.text}>{text}</p>
                    <div className={styles.rating}>
                        {'★'.repeat(rating).padEnd(5, '☆')}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial
