import React from 'react'
import Hero from './Hero/Hero'
import About from '../About/About'
import MentorsSection from '../Mentors/MentorsSection'
import TestimonialsSection from '../Testimonial/TestimonialsSection'
import PlacementPreparation from '../services/PlacementPreparation'

const Home = () => {
    return (
        <>
            <Hero />
            <PlacementPreparation />
            <About />
            <MentorsSection />
            <TestimonialsSection />
        </>
    )
}

export default Home
