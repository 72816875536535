
import React from 'react';
import './Footer.css';
import Logo from './IQLOGO.png'; //link logo of the compnay

const Footer = () => {
  return (


    <>
      <section className='newletter'>
        <div className='container flexSB'>
          <div className='left row'>
            <h1>Newsletter - Stay tune and get the latest update</h1>
            <span>know about insights and interview tips  of IQpath early from rest ...</span>
          </div>
          <div className='right row'>
            <input type='text' placeholder='Enter email address' />
            {/* <i className='fa fa-paper-plane'></i> */}
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="container">
          <div className="footer-section logo-section">
            <img src={Logo} alt="Company Logo" className="logoF" />
            <h3>Master Your Future: Excel in AI, ML, and Deep Learning with Mock Interviews and Workshops</h3>
          </div>

          {/* <div className="footer-section about">
            <h2>About Us</h2>
            <p>
              Welcome to our education website. We provide a variety of resources
              and tools to help you achieve your learning goals.
            </p>
          </div> */}



          <div className="footer-section links link" >
            <h2>Quick Links</h2>
            <ul >
              <li><a href="/">Home</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/">Service</a></li>
              <li><a href="/">Courses</a></li>
              <li><a href="/">Blog</a></li>
              <li><a href="/contactus">Contact Us</a></li>
              <li><a  href="/privacypolicy">privacypolicy</a></li>
              <li><a  href="/termsandcondition">terms and condition</a></li>
              <li><a  href="/cancellationPolicy">cancellation and refund</a></li>
            </ul>
          </div>

          <div className="footer-section links link">
            <h2>Explore</h2>
            <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#courses">Courses</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/contactus">Contact</a></li>
            </ul>
          </div>


          <div className="footer-section contact">
            <h2>Contact Us</h2>
            <p><i className="fas fa-phone"></i> +91 8120390205</p>
            <p><i className="fas fa-envelope"></i> info@iqpaths.com</p>
            <p> contact address : 301 shree lakshmi leela vihar appartment 74, pricanco colony ,indore madhya pradesh 452009</p>
          </div>
        </div>

        <div className="footer-bottom" >
          &copy; all right reserverd 
        </div>
      </footer>
    </>
  );
};

export default Footer;
