import React from 'react'
import "./ContactUs.css"

const ConatctUs = () => {
    return (
        <>
            <div className="contact-us-container">
                <h1>Contact Us</h1>
                <p>We'd love to hear from you! Reach out to us for any queries or support.</p>
                <p>contact number :+918120390205</p>
                <p>contact Address:  301 shree lakshmi leela vihar appartment 74, pricanco colony ,indore madhya pradesh 452009 ,India</p>
                <form className="contact-form">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" rows="5" required></textarea>
                    </div>
                    <button type="submit" className="submit-button">Send Message</button>
                </form>
            </div>
        </>
    )
}

export default ConatctUs
