
export default [
    {
      "id": 1,
      "topicName": "Placement Guidance Program Basic",
      "description":["Resume Building","Tips & Strategies to handle personal interview","Company Specific Mock Technical Interview","Interviewer from top MNCs Like: TCS, Capgemini, Accenture, J.P. Moron, IBM…","job Assistance"],
      "image": "./assets/resume.png",
      "price": "349",
      "slug_name": "Placement-Guidance-Program-Basic"
    },
    {
      "id": 2,
      "topicName": "Placement Preparation Program Premium",
      "description":["Aptitude Test","Resume Building","HR round preparation","Technical Interview","Company Specific Mock Technical Interview ","Tips & Strategies to handle personal interview","Interviewer from top MNCs Like: TCS, Capgemini, Accenture, J.P. Moron, IBM...","Job Assistance"],
      "image": "./assets/mockinter.png",
      "price": "449",
      "slug_name": "Placement-Preparation-Program-Premium"
    },
    {
      "id": 3,
      "topicName": "Placement Preparation Program Ultimate",
      "description":["Aptitude Test","Coding Mock Test","Resume Building","HR round preparation","Company Specific Mock Technical Interview ","Tips & Strategies to handle personal interview","Interviewer from top MNCs Like: TCS, Capgemini, Accenture, J.P. Moron, IBM...","Job Assistance"],
      "image": "./assets/image.png",
      "price": "849",
      "slug_name": "Placement-Preparation-Program-Ultimate"
    }
]
  