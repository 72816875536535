// import React, { useEffect, useState } from 'react'
import classes from "./modulepage.module.css";

import image from "./banner.jpeg";
import { useParams } from "react-router-dom";
import dataset from "../../Utils/data";
import { useEffect, useState } from "react";
import PaymentForm from "../PaymentForm/PaymentForm";
function ModulePage() {
  const { slugname } = useParams();
  const getdata = dataset.find((item) => item.slug_name === slugname);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [IsModalOpen, setModalOpen] = useState(false);
  const handleModal = () => {
    setModalOpen(!IsModalOpen);
  };
  return (
    // <div className='container'>
    <div className={classes.modulepage}>
      {getdata ? (
        <>
          <div className={classes.moduleImage}>
            <img src={image} alt="banner" />
          </div>
          <div className={classes.detail}>
            <div className={classes.topicName}>{getdata.topic_name}</div>
            <div className="hero-buttons">
              <button onClick={handleModal} className={classes.btnPrimary}>
                Buy Now
              </button>
              <a href="/" className={classes.btnsecondary}>
                Login to Portal <span>&#8594;</span>
              </a>
            </div>
            <div className={classes.description}>
              <h2>Description</h2>
              <p> {getdata.description}</p>
            </div>
            <div className={classes.knowmore}>
              <h2>Things To Know More </h2>
              <p>
                <b>Amount in Rs: {getdata.know_more.price} </b>
              </p>
              <p>
                <b>Topics</b>
              </p>
              <p>
                {getdata.know_more.things_covered.map((item) => (
                  <li>{item}</li>
                ))}
              </p>
              <p>
                <b>Message :</b>
                {getdata.message}
              </p>
              <p>
                <b>contact mail :</b>
                {getdata.know_more.contact_mail}
              </p>
              <p>
                <b>Open Now : {getdata.live_now}</b>
              </p>
            </div>
            <p>
              <b>Note: </b>If you have paid and didnt recieve email from us with
              in 1hr then you can just mail us at our mail or reach out through
              contact details
            </p>
            {IsModalOpen ? <PaymentForm closeModal={handleModal} /> : <></>}
          </div>
        </>
      ) : (
        <div style={{ marginTop: 400 }}> data is loading..</div>
      )}
    </div>
    // </div>
  );
}

export default ModulePage;
