import React from 'react'
import styles from './PaymentForm.module.css';
import { useState,useCallback } from 'react';
function PaymentForm({ handlePayNow, closeModal }) {
    const [fullName, setFullName] = useState("");
    
    const [contactNumber, setContactNumber] = useState("");
    const [emailAdd, setEmailAdd] = useState("");
  
    const handleSubmit = useCallback(
      (e) => {
        e.preventDefault();
        handlePayNow({ name: fullName, contactNumber, email: emailAdd });
      },
      [fullName, contactNumber, emailAdd, handlePayNow],
    );
  
    return (
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.overlay}`} onClick={() => closeModal()} />
        <div
          className={`${styles.container} d-flex flex-column gap-4 position-relative`}
        >
          <button
            className={`${styles.backBtn} position-absolute`}
            onClick={closeModal}
          >
            Back
          </button>
          <h1>iQPaths</h1>
          <div>
            <p>
            Boost your placement preparation with IQ Paths.Join us to enhance your skills and secure your future.
            </p>
            <p>
              <strong>Place Your Order Now!</strong>
            </p>
          </div>
          <form
            className={`${styles.form} d-flex flex-column gap-4`}
            onSubmit={handleSubmit}
          >
            <label>
              Full Name
              <input
                type="text"
                placeholder="Your Full Name"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </label>
            <label>
              Contact Number
              <input
                type="tel"
                placeholder="Your 10 digit contact number"
                required
                minLength={10}
                maxLength={10}
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </label>
            <label>
              E-Mail
              <input
                type="email"
                placeholder="Your Email Address"
                value={emailAdd}
                onChange={(e) => setEmailAdd(e.target.value)}
                required
              />
            </label>
            <button type="submit" className={`${styles.submitBtn} btn mt-2`}>
              <span>
                <small>Pay Now !</small>
                <small>Pay Now !</small>
              </span>
            </button>
          </form>
        </div>
      </div>
    )
}

export default PaymentForm