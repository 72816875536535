import React from 'react'
import HeroImage from "./image.png"
import "./Hero.css"

const Hero = () => {
    return (
        <>
            <section className="hero">
                <div className="hero-content">
                    <h1>Welcome to IQPaths</h1>
                    <h2>Plan, Prepare, Perform!</h2>
                    <p>
                        Boost your placement preparation with IQ Paths. Get expert guidance from top IT professionals, tailored services, and the support you need to succeed in your career.Join us to enhance your skills and secure your future.
                    </p>
                    <div className="hero-buttons">
                        <a href="/" className="btn primary">Explore</a>
                        <a href="/" className="btn secondary">Login <span>&#8594;</span></a>
                    </div>
                </div>
                <div className="hero-image">
                    <img src={HeroImage} alt="Hero" />
                </div>
            </section>
        </>
    )
}

export default Hero
