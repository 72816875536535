import React from 'react'
import Testimonial from './Testimonial';
import styles from './TestimonialsSection.module.css';

import aakrati from "./aakratijain.jpg"
import vaishnavi from "./vaishnavi.jpg"
import jayesh from "./jayeshPhoto.jpg"

const testimonials = [
    {
        imgSrc: aakrati,
        name: 'AAKRATI JAIN',
        text: 'Dr. Sanket made complex topics engaging and easy to understand. His clear explanations, interactive examples, and valuable interview tips helped me land my dream job. An exceptional educator, he goes above and beyond.',
        rating: 5,
        post: "STUDENT NMIMS",
    },
    {
        imgSrc: vaishnavi,
        name: 'Vaishnavi',
        text: 'IQPaths has been a game-changer for my career. Mock interviews with industry experts and IIT professors boosted my confidence. Workshops and real-world projects deepened my AI and ML knowledge, and certifications enhanced my profile. Highly recommended!',
        rating: 5,
        post: "Ai/Ml developer Impetus ",
    },
    {
        imgSrc: jayesh,
        name: 'Jayesh Tapdiya',
        text: 'IQPaths has been an invaluable resource in my career journey within the IT industry. The mock interview sessions were particularly enlightening, providing a realistic simulation of actual job interviews and helping me identify areas for improvement in my communication and technical skills.',
        rating: 5,
        post: "STUDENT Medi-Caps University",
    },
];

const TestimonialsSection = () => {
    return (
        <>
            <section className={styles.testimonialsSection}>
                <h2>Testimonials</h2>
                <p className={styles.sectionDescription}>Learn from the best in the industry.</p>

                <div className={styles.testimonials}>
                    {testimonials.map((testimonial, index) => (
                        <Testimonial
                            key={index}
                            imgSrc={testimonial.imgSrc}
                            name={testimonial.name}
                            text={testimonial.text}
                            rating={testimonial.rating}
                            post={testimonial.post}
                        />
                    ))}
                </div>
            </section>
        </>
    )
}

export default TestimonialsSection
