import React from 'react'
import Mentors from './Mentors';
import styles from './MentorsSection.module.css';

import nishtha from "./nishthaGoud.jpg"
import abhinav from "./AbhinavPathak.jpg"
import prathibha from "./pratibha.jpeg"
import tejalal from "./Tejalal.jpeg"

const mentors = [
    {
        imgSrc: tejalal,
        name: 'Dr. Tejalal Choudhary',
        title: 'Data Scientist, Ph.D. (Bennett University)',
        description: '',
        linkdin: "https://www.linkedin.com/in/tejalal?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BBLmDblLoQGuCX3%2BTLxyPVA%3D%3D",

    },
    {
        imgSrc: prathibha,
        name: 'Ms. Prathibha Agarwal',
        title: '',
        description: 'Empowering Growth Through Soft Skills | Training Individuals for Success | Communication, Leadership, and Career Development Exper',
        linkdin: "https://www.linkedin.com/in/pratibha-agrawal-abb15042?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BfC95na6%2BS%2Bi5yBAiq5T7vw%3D%3D",

    },
    {
        imgSrc: nishtha,
        name: 'Ms. Nishtha Goud',
        title: 'Senior Consultant, Capgemini, Uk',
        description: '',
        linkdin: "https://www.linkedin.com/in/nishtha-goud-87971758/",

    },
    {
        imgSrc: abhinav,
        name: 'Dr. Abhinav Pathak',
        title: 'IIT, Ropar',
        description: "",
        linkdin: "https://www.linkedin.com/in/abhinav-pathak-08456616/",

    },



];

const MentorsSection = () => {
    return (
        <>
            <section className={styles.mentorsSection}>
                <h2>Meet Our Mentors</h2>
                <p className={styles.sectionDescription}>
                    We focus on the details of everything we do. All to help businesses around the world. Focus on what's most important to them.
                </p>
                <div className={styles.mentors}>
                    {mentors.map((mentor, index) => (
                        <Mentors
                            key={index}
                            imgSrc={mentor.imgSrc}
                            name={mentor.name}
                            title={mentor.title}
                            description={mentor.description}
                            linkdin={mentor.linkdin}
                            twitter={mentor.twitter}
                            gmail={mentor.gmail}
                        />
                    ))}
                </div>
            </section>
        </>
    )
}

export default MentorsSection
