import React from 'react';
import './App.css';
import Navbar from './Compoments/Common/Navbar/Navbar';
import 'bootstrap/dist/css/bootstrap.css';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './Compoments/Home/Home';
import Footer from './Compoments/Common/Footer/Footer';
import About from './Compoments/About/About';
import MentorsSection from './Compoments/Mentors/MentorsSection';
import TestimonialsSection from './Compoments/Testimonial/TestimonialsSection';
import ModulePage from './Pages/servicesSingleCard/ModulePage';
import TermsAndCondition from './Compoments/Common/TermsAndCondition/TermsAndCondition';
import PrivacyPolicy from './Compoments/Common/PrivacyPolicy/PrivacyPolicy';
import AboutUs from './Compoments/AboutUs/AboutUs';
import ConatctUs from './Compoments/ContactUs/ConatctUs';
import PlacementPreparation from './Compoments/services/PlacementPreparation';
import CancellationPolicy from './Compoments/Common/CancellatonPolicy/CancellationPolicy';



const App = () => {

  return (
    <>

      <Router>
        <div className='container'>

          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/moduleCard/:slugname" element={<ModulePage elem={"hello"} />} />
            <Route exact path='/aboutus' element={<AboutUs />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path='/contactus' element={<ConatctUs />} />
            <Route exact path='/course' element={<PlacementPreparation />} />
            <Route exact path="/mentor" element={<MentorsSection />} />
            <Route exact path="/testimonials" element={<TestimonialsSection />} />
            <Route exact path="/termsandcondition" element={<TermsAndCondition />} />
            <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route exact path="/cancellationPolicy" element={<CancellationPolicy />} />
          </Routes>
        </div>

        <Footer />
      </Router>

    </>

  );
}

export default App;
