import React from "react";
// import { FaFacebookF, FaGithub, FaLinkedinIn } from 'react-icons/fa';
import "./AboutUs.css";
import ceo from "./sanketsir.png";

const AboutUs = () => {
  return (
    <>
      <div className="profile-card">
        <div className="profile-details">
          <h2>Founder, IQ Paths </h2>
          <h1>Mr. Sanket Gupta</h1>
          <p>
            IQPaths is your transformative journey towards a successful future.
            Our mission is to provide personalized guidance, valuable resources,
            and unparalleled support throughout your placement journey. We
            assist you in excelling in interviews with expert advice, reviews,
            and mock sessions. We also provide exclusive access to job and
            internship opportunities, connecting you with leading companies
            across various industries. Stay informed about the latest
            advancements in the technological world, with a special emphasis on
            AI, ML, and Data Analytics.
          </p>
          <div className="profile-social">
            {/* <a href="facebook-url" target="_blank" rel="noopener noreferrer">
              <img src="" alt="Facebook" />
            </a>
            <a href="github-url" target="_blank" rel="noopener noreferrer">
              <img src="" alt="GitHub" />
            </a>
            <a href="linkedin-url" target="_blank" rel="noopener noreferrer">
              <img src="" alt="LinkedIn" />
            </a> */}
          </div>
        </div>
        <div className="profile-image">
          <img src={ceo} alt="Sanket Gupta" />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
